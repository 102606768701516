<template>
  <div>
    <div class="view pa20 mr" style="border-bottom: 1px solid #E6EBEB;">
          <span v-if="upDate==1"
                style="color: #51CBCD; font-size: 14px;border-bottom: 3px solid #51CBCD;;padding-bottom: 20px;margin-left: 20px"
          >新增页面</span
          >
      <span v-if="upDate==2"
            style="color: #51CBCD; font-size: 14px;border-bottom: 3px solid #51CBCD;;padding-bottom: 20px;margin-left: 20px"
      >编辑页面</span
      >
    </div>
    <el-form
        ref="form" :model="form" :rules="rules" label-width="auto" class="view pa24" label-position="left"
    >
      <el-form-item prop="name" label="页面名称">
        <el-input
            v-model="form.name"
            style="width: 500px"
            placeholder="请输入页面名称"
            maxlength="30"
            show-word-limit
        ></el-input>
      </el-form-item>
      <hr
          style="
          border: none;
          height: 1px;
          margin: 10px 0;
        "
      />
      <el-form-item label="页面类型" prop="type">
        <el-radio-group v-model="form.type" @change="switchNav">
          <el-radio :label="1" :disabled="disabled">商品</el-radio>
          <el-radio :label="2" :disabled="disabled1">文章</el-radio>
        </el-radio-group>
      </el-form-item>
      <hr
          style="
          border: none;
          height: 1px;
          margin: 10px 0;
        "
      />
      <el-form-item prop="shopList" label="" v-if="form.type==1">
        <div class="shop_wrap">
          <div class="type_list">
            <div class="type_item" :class="activeIndex==index?'classAA':'classBB'" @click.stop="chooseType(index)"
                 v-for="(item,index) in shopTypeList" :key="index">{{ item.name }}
              <img src="../../assets/images/close_ZDY.png" alt="" v-if="item.ifShow==1" @click.stop="deleteType(index)"
                   style="position: absolute;right: -9px;top: -9px;height: 18px;width: 18px">
            </div>
            <el-button type="primary" class="ma ml10" @click="open" :disabled="disabled2"
            >+ 添加分类
            </el-button
            >
          </div>
          <div style="display: flex;justify-content: center;margin: 40px 0">
            <div
                style="margin-right: 20px;cursor: pointer;color: #51CBCD;text-align: center;border: 1px dashed #51CBCD;width: 130px;border-radius: 8px;display: flex;justify-content: center"
                @click="chooseShop">
              <img src="../../assets/images/shop_ZDY.png" alt="" style="width: 14px;height: 14px;align-self: center;margin-right: 8px"> 选择商品
            </div>
            <div
                style="cursor: pointer;color: #51CBCD;text-align: center;border: 1px dashed #51CBCD;width: 130px;border-radius: 8px;display: flex;justify-content: center"
                @click="chooseyuyueShop">
              <img src="../../assets/images/shop_ZDY.png" alt="" style="width: 14px;height: 14px;align-self: center;margin-right: 8px"> 选择预约商品
            </div>
          </div>
          <div v-for="(item,index) in chooseShopList" :key="'a' + index">
            <div class="choose_list" v-if="index == activeIndex">
              <div class="choose_item" v-for="(item1,index1) in item" :key="index1">
                <div class="choose_left">
                  <img :src="item1.coverImg">
                  <div style="flex: 1">
                    <span>{{ item1.goodsName }}</span>
                    <div class="del">
                      <span>￥ {{ item1.price2?item1.price2:item1.price }}</span>
                      <img src="../../assets/images/delete_ZDY.png" alt="" @click="delShop(index1)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="chooseShopYYList.length != 0">
            预约商品
          </div>
          <div v-for="(item,index2) in chooseShopYYList" :key="'B'+index2">
            <div class="choose_list" v-if="index2 == activeIndex">
              <div class="choose_item" v-for="(item1,index3) in item" :key="index3">
                <div class="choose_left">
                  <img :src="item1.productsPhoto">
                  <div style="flex: 1">
                    <span>{{ item1.productsName }}</span>
                    <div class="del">
                      <span>￥ {{ item1.price }}</span>
                      <img src="../../assets/images/delete_ZDY.png" alt="" @click="delShopYY(index3)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item  v-if="form.type==2">
        <div class="shop_wrap">
          <div class="type_list">
            <div class="type_item" :class="activeIndex1==index?'classAA':'classBB'" @click="chooseType1(index)"
                 v-for="(item,index) in typeList_WZ" :key="index">{{ item.name }}
              <img src="../../assets/images/close_ZDY.png" alt="" v-if="item.ifShow==1" @click.stop="deleteType1(index)"
                   style="position: absolute;right: -9px;top: -9px;height: 18px;width: 18px">
            </div>
            <el-button type="primary" class="ma ml10" @click="open1"
            >+ 添加分类
            </el-button
            >
          </div>
          <div style="display: flex;justify-content: center;margin: 40px 0">
            <div
                style="cursor: pointer;color: #51CBCD;text-align: center;border: 1px dashed #51CBCD;width: 130px;border-radius: 8px;height: 34px"
                @click="centerDialogVisible2 = true,articleTitle = '发布文章',upDateART = 1">
              + 新增文章
            </div>
            <div
                style="cursor: pointer;color: #51CBCD;text-align: center;border: 1px dashed #51CBCD;width: 130px;border-radius: 8px;height: 34px;margin-left: 30px"
                @click="chooseWZ">
              <img src="../../assets/images/wenzhang_ZDY.png" alt="" style="width: 14px;height: 14px"> 引用文章
            </div>
          </div>
          <div v-for="(item,index) in chooseArticeList" :key="index">
            <div class="choose_list" v-if="index == activeIndex1">
              <div class="choose_item1" v-for="(item1,index1) in item" :key="index1">
                <div class="choose_left1">
                  <img :src="item1.coverUrl?item1.coverUrl:item1.img">
                  <div style="flex: 1">
                    <span>{{ item1.name?item1.name:item1.title }}</span>
                  </div>
                  <div class="del">
                    <img src="../../assets/images/delete_ZDY.png" alt="" @click="delWZ(index1)">
                    <img src="../../assets/images/update_ZDY.png" alt="" @click="updateWZ(index1)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <hr
          style="
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <el-form-item label-width="80px">
        <el-button
            type="primary"
            size="medium"
            @click="addGood"
        >发布
        </el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog
        title="+ 新增分类"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
        @close="close_type"
    >
      <el-form
          ref="ruleForm_type" :model="ruleForm_type" :rules="rules_type" label-width="auto" class="view pa24"
          label-position="left"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
              placeholder="请输入分类名称"
              v-model="ruleForm_type.name"
              clearable
              maxlength="50"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="+ 新增分类"
        :visible.sync="centerDialogVisible4"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
        @close="close_type1"
    >
      <el-form
          ref="ruleForm_type1" :model="ruleForm_type1" :rules="rules_type1" label-width="auto" class="view pa24"
          label-position="left"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
              placeholder="请输入分类名称"
              v-model="ruleForm_type1.name"
              clearable
              maxlength="50"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="determine_wz()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择商品"
        :visible.sync="centerDialogVisible1"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-table :data="shopList" style="width: 100%" @selection-change="handleSelectionChange" :loading="loading">
        <el-table-column type="selection"/>
        <el-table-column prop="goodsName" label="商品名称" align="center"></el-table-column>
        <el-table-column label="商品图片" align="center">
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 40px" :src="scope.row.coverImg"
                      :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="商品价格" align="center"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible1 = false">取 消</el-button>
				<el-button type="primary" @click="chooseShopQD">确 定</el-button>
			</span>
    </el-dialog>
    <el-dialog
        title="选择预约商品"
        :visible.sync="centerDialogVisible6"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-table :data="shopyyList" style="width: 100%" @selection-change="handleSelectionChange3" :loading="loading">
        <el-table-column type="selection"/>
        <el-table-column prop="productsName" label="商品名称" align="center"></el-table-column>
        <el-table-column label="商品图片" align="center">
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 40px" :src="scope.row.productsPhoto"
                      :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="商品价格" align="center"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible6 = false">取 消</el-button>
				<el-button type="primary" @click="chooseShopYY">确 定</el-button>
			</span>
    </el-dialog>
    <el-dialog :title="articleTitle" :visible.sync="centerDialogVisible2" :modal-append-to-body="false" width="50%" center
               @close="close">
      <el-form :model="ruleFormAdd" :rules="rules_ADD" ref="ruleFormADD" label-width="150px">
        <el-form-item label="文章名称" prop="title">
          <el-input clearable v-model="ruleFormAdd.title" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <!--        <el-form-item label="排序" prop="sort">-->
        <!--          <el-input clearable v-model="ruleFormAdd.sort" maxlength="11" min="0"-->
        <!--                    @input="ruleFormAdd.sort=ruleFormAdd.sort.replace(/^0(0+)|[^\d]+/g,'')" show-word-limit></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="图片" prop="coverImg">
          <el-upload :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
               disabled: uploadDisabled
            }" :action="$store.state.uploadingUrl" list-type="picture-card" :on-progress="handProgress"
                     :on-success="handleAvatarSuccessCoverImg" :on-remove="handleRemove"
                     :before-upload="beforeAvatarUploadImg" :file-list="fileList" :limit="1">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              图片上传比例为3:4，支持格式png、jpg、jpeg，大小不超过1M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容" prop="details">
          <quill-editor @ready="ready($event)" style="height: 100%" v-model="ruleFormAdd.details"
                        ref="myQuillEditor" :options="editorOption" @change="onEditorChange($event)"/>
          <span class="wordNumber">{{ TiLength }}/8000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible2 = false">取 消</el-button>
				<el-button type="primary" @click="determine_upload">确 定</el-button>
			</span>
    </el-dialog>
    <el-dialog
        title="选择文章"
        :visible.sync="centerDialogVisible3"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-table :data="tableData_wz" style="width: 100%;height: 450px;overflow: auto" @selection-change="handleSelectionChange1" :loading="loading">
        <el-table-column type="selection"/>
        <el-table-column prop="name" label="标题" align="center"></el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 40px" :src="scope.row.coverUrl"
                      :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible3 = false">取 消</el-button>
				<el-button type="primary" @click="chooseArtice">确 定</el-button>
			</span>
    </el-dialog>
<!--    <el-dialog title="修改文章" :visible.sync="centerDialogVisible5" :modal-append-to-body="false" width="50%" center-->
<!--               @close="close">-->
<!--      <el-form :model="ruleFormAdd" :rules="rules_ADD" ref="ruleFormADD" label-width="150px">-->
<!--        <el-form-item label="文章名称" prop="title">-->
<!--          <el-input clearable v-model="ruleFormAdd.title" maxlength="200" show-word-limit></el-input>-->
<!--        </el-form-item>-->
<!--        &lt;!&ndash;        <el-form-item label="排序" prop="sort">&ndash;&gt;-->
<!--        &lt;!&ndash;          <el-input clearable v-model="ruleFormAdd.sort" maxlength="11" min="0"&ndash;&gt;-->
<!--        &lt;!&ndash;                    @input="ruleFormAdd.sort=ruleFormAdd.sort.replace(/^0(0+)|[^\d]+/g,'')" show-word-limit></el-input>&ndash;&gt;-->
<!--        &lt;!&ndash;        </el-form-item>&ndash;&gt;-->
<!--        <el-form-item label="图片" prop="coverImg">-->
<!--          <el-upload :class="{-->
<!--              'avatar-uploader': true,-->
<!--              'avatar-coverImg': true,-->
<!--               disabled: uploadDisabled-->
<!--            }" :action="$store.state.uploadingUrl" list-type="picture-card" :on-progress="handProgress"-->
<!--                     :on-success="handleAvatarSuccessCoverImg" :on-remove="handleRemove"-->
<!--                     :before-upload="beforeAvatarUploadImg" :file-list="fileList" :limit="1">-->
<!--            <i class="el-icon-plus avatar-uploader-icon"></i>-->
<!--            <div slot="tip" class="el-upload__tip">-->
<!--              图片上传比例为3:4，支持格式png、jpg、jpeg，大小不超过1M-->
<!--            </div>-->
<!--          </el-upload>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="内容" prop="details">-->
<!--          <quill-editor @ready="ready($event)" style="height: 100%" v-model="ruleFormAdd.details"-->
<!--                        ref="myQuillEditor" :options="editorOption" @change="onEditorChange($event)"/>-->
<!--          <span class="wordNumber">{{ TiLength }}/8000</span>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--				<el-button @click="centerDialogVisible5 = false">取 消</el-button>-->
<!--				<el-button type="primary" @click="determine_upload_update">确 定</el-button>-->
<!--			</span>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {getGoodsInfoPC,} from "@/api/videoManage";
import {addCustomPage, queryCustomPageById, selectWorkList,updatePageWork,delPageWork,updateCustomPageById,selectProductsPage} from "@/api/content";
import {
  lineHeightStyle
} from "@/utils/lineHeight.js";
import {
  quillEditor,
  Quill
} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {
  ImageDrop
} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

quillConfig.placeholder = '请填写内容'

export default {
  name: 'addCustomPage',
  components: {},
  data() {
    return {
      upDateART:1,
      articleTitle:'发布文章',
      index:0,
      disabled: false,
      disabled1: false,
      TiLength: 0,
      editorOption: quillConfig,
      ruleForm: {
        name: ''
      },
      srcList: [],
      shopList: [],
      articeList: [],
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum1: 1,
      pageSize1: 10,
      loading1: false, //加载中
      total1: 0, //总条数
      currentPage1: 1, //当前页数
      centerDialogVisible1: false,
      centerDialogVisible2: false,
      centerDialogVisible3: false,
      centerDialogVisible: false,
      centerDialogVisible4: false,
      centerDialogVisible5: false,
      centerDialogVisible6: false,
      activeIndex: 0,
      activeIndex1: 0,
      shopTypeList: [],
      form: {
        name: '',
        type: 1
      },
      ruleForm_type: {
        name: ''
      },
      ruleForm_type1: {
        name: ''
      },
      rules: {
        name: [{required: true, message: "请输入页面名称"}],
        type: [{required: true, message: "请选择页面类型"}],
      },
      rules_type: {
        name: [{required: true, message: "请输入分类名称"}],
      },
      rules_type1: {
        name: [{required: true, message: "请输入分类名称"}],
      },
      ruleFormAdd: {
        title: '',
        sort: '',
        coverImg: '',
        details: '',
      },
      rules_ADD: {
        title: [{required: true, message: "请输入文章名称"}],
        // sort: [{required: true, message: "请输入排序"}],
        coverImg: [{required: true, message: "请上传图片"}],
        details: [{required: true, message: "请输入内容", trigger: "blur"}],
      },
      fileList: [],
      multipleSelection: [],
      multipleSelection1: [],
      multipleSelection3:[],
      chooseShopList: [],
      chooseShopYYList: [],
      linshiList: [],
      upDate: 1,
      upDate1:1,
      typeList_WZ: [],
      tableData_wz: [],
      chooseArticeList:[],
      linshiList_wz:[],
      dataId:'',
      id:'',
      disabled2:false,
      shopyyList:[],
      linshiYYList:[]
    };
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  },
  async created() {

  },
  watch: {
    chooseShopList(newName){
      console.log(newName)
      // this.watchInputValue = newName
    }
  },
  mounted() {
    let id = this.$route.query.dataId
    this.id = id
    if (id) {
      this.getDetails(id)
      this.upDate = 2
    }
  },
  methods: {
    async getDetails(id) {
      this.upDate = 2
      const details = await queryCustomPageById({dataId: id});
      console.log(details.data)
      this.form.name = details.data.pageName
      this.form.type = details.data.typeState
      if (details.data.typeState == 1) {
        this.disabled = false
        this.disabled1 = true
      } else if (details.data.typeState == 2) {
        this.disabled1 = false
        this.disabled = true
      }
      if (details.data.typeState == 1) {
        if (details.data.pageTypeModelList[0].pageTypeModelList) {
          this.shopTypeList = details.data.pageTypeModelList[0].pageTypeModelList
          let b = {}
          for (let i in this.shopTypeList) {
            for (let j in this.shopTypeList[i].goodsModelList) {
              this.shopTypeList[i].goodsModelList[j].price = (this.shopTypeList[i].goodsModelList[j].price / 100).toFixed(2)
            }
            if (this.shopTypeList[i].goodsModelList) {
              this.chooseShopList.push(b[i] = this.shopTypeList[i].goodsModelList)
            }
            if (this.shopTypeList[i].appointmentGoodsModelList) {
              this.chooseShopYYList.push(b[i] = this.shopTypeList[i].appointmentGoodsModelList)
            }
          }
        }else {
          let b = {}
          this.disabled2 = true
          if (details.data.pageTypeModelList[0].appointmentGoodsModelList) {
            this.chooseShopYYList.push(b = details.data.pageTypeModelList[0].appointmentGoodsModelList)
          }
          if (details.data.pageTypeModelList[0].goodsModelList) {
            this.chooseShopList.push(b = details.data.pageTypeModelList[0].goodsModelList)
          }
        }
      }else {
        if (details.data.pageTypeModelList[0].pageTypeModelList) {
          this.typeList_WZ = details.data.pageTypeModelList[0].pageTypeModelList
          let b = {}
          for (let i in this.typeList_WZ) {
            if (this.typeList_WZ[i].pageWorkRequestList) {
              this.chooseArticeList.push(b[i] = this.typeList_WZ[i].pageWorkRequestList)
            }else {
              this.chooseArticeList.push(b[i] = [])
            }
          }
        }else{
          let b = {}
          console.log(details.data.pageTypeModelList[0].pageWorkRequestList)
          this.disabled2 = true
          this.chooseArticeList.push(b = details.data.pageTypeModelList[0].pageWorkRequestList)
        }

      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val;
    },
    handleSelectionChange3(val) {
      this.multipleSelection3 = val;
    },
    chooseType(index) {
      this.activeIndex = index
    },
    chooseType1(index) {
      this.activeIndex1 = index
    },
    open() {
      this.centerDialogVisible = true
    },
    open1() {
      this.centerDialogVisible4 = true
    },
    addGood() {
      if (this.form.type == 1) {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            try {
              this.$message({
                message: "正在保存",
              });
              let ids = []
              console.log(this.chooseShopList)
              for (let i in this.chooseShopList) {
                if (this.chooseShopList[i]) {
                  var newArr = this.chooseShopList[i].map((item, index) => {
                    return item.goodsId
                  })
                  ids.push(newArr)
                }
              }
              let idyy = []
              for (let i in this.chooseShopYYList) {
                if (this.chooseShopYYList[i]) {
                  var newArr = this.chooseShopYYList[i].map((item, index) => {
                    return item.productsId
                  })
                  idyy.push(newArr)
                }
              }
              let pageTypeModelList = []
              let pageTypeModelYYList = []
              let data = {}
              if ( this.shopTypeList.length == 0 ) {
                pageTypeModelList = ids.join(',')
                pageTypeModelYYList = idyy.join(',')
                data = {
                  associationId: localStorage.getItem("associationId"),
                  pageName: this.form.name,
                  typeState: this.form.type,
                  contentGoodIds: pageTypeModelList,
                  appointmentGoodsIds:pageTypeModelYYList
                };
              }else {
                for (let i in this.shopTypeList) {
                  for (let j in ids) {
                    if (i == j) {
                      if (this.upDate == 1) {
                        pageTypeModelList.push({name: this.shopTypeList[i].name, contentGoodIds: ids[j]?ids[j].join(','):'',appointmentGoodsIds:idyy[j]?idyy[j].join(','):''})
                      }else {
                        pageTypeModelList.push({name: this.shopTypeList[i].name, contentGoodIds: ids[j]?ids[j].join(','):'',appointmentGoodsIds:idyy[j]?idyy[j].join(','):'',dataId:this.shopTypeList[i].dataId})
                      }
                    }
                  }
                }
                data = {
                  associationId: localStorage.getItem("associationId"),
                  pageName: this.form.name,
                  typeState: this.form.type,
                  pageTypeModelList: pageTypeModelList
                };
              }
              console.log(data)
              // return
              if (this.upDate == 1) {
                await addCustomPage(data);
              } else {
                data.dataId = this.id
                await updateCustomPageById(data)
              }
              this.centerDialogVisible = false;
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$router.push('/customPage')
            } catch (error) {
              this.$message.closeAll();
              this.$message({
                message: error.message,
                type: "error",
              });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }else {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            try {
              this.$message({
                message: "正在保存",
              });
              console.log(this.chooseArticeList)
              for (let i in this.chooseArticeList) {
                for (let j in this.chooseArticeList[i]) {
                  this.chooseArticeList[i][j]['title'] = this.chooseArticeList[i][j].name?this.chooseArticeList[i][j].name: this.chooseArticeList[i][j].title
                  this.chooseArticeList[i][j]['img']= this.chooseArticeList[i][j].coverUrl?this.chooseArticeList[i][j].coverUrl: this.chooseArticeList[i][j].img
                  this.chooseArticeList[i][j]['content'] = this.chooseArticeList[i][j].remark?this.chooseArticeList[i][j].remark:this.chooseArticeList[i][j].content
                }
              }
              let pageTypeModelList = []
              let data = {}
              if (this.typeList_WZ.length == 0) {
                pageTypeModelList = this.chooseArticeList[0]
                data = {
                  associationId: localStorage.getItem("associationId"),
                  pageName: this.form.name,
                  typeState: this.form.type,
                  pageWorkRequestList: pageTypeModelList
                };
              }else{
                for (let i in this.typeList_WZ) {
                  for (let j in this.chooseArticeList) {
                    if (i == j) {
                      if (this.upDate == 1) {
                        pageTypeModelList.push({name: this.typeList_WZ[i].name, pageWorkRequestList: this.chooseArticeList[j]})
                      }else {
                        pageTypeModelList.push({name: this.typeList_WZ[i].name, pageWorkRequestList: this.chooseArticeList[j],dataId:this.typeList_WZ[i].dataId})
                      }
                    }
                  }
                }
                data = {
                  associationId: localStorage.getItem("associationId"),
                  pageName: this.form.name,
                  typeState: this.form.type,
                  pageTypeModelList: pageTypeModelList
                };
              }
              if (this.upDate == 1) {
                await addCustomPage(data);
              } else {
                data.dataId = this.id
                await updateCustomPageById(data)
              }
              this.centerDialogVisible = false;
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$router.push('/customPage')
            } catch (error) {
              this.$message.closeAll();
              this.$message({
                message: error.message,
                type: "error",
              });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    async determine() {
      if (this.ruleForm_type.name) {
        this.shopTypeList.push({name: this.ruleForm_type.name, ifShow: 1})
        this.centerDialogVisible = false
      } else {
        this.$message.error('请输入分类名称')
      }

    },
    async determine_wz() {
      console.log('00')
      if (this.ruleForm_type1.name) {
        this.typeList_WZ.push({name: this.ruleForm_type1.name, ifShow: 1})
        this.centerDialogVisible4 = false
      } else {
        this.$message.error('请输入分类名称')
      }

    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        companyName: this.enterpriseName,
        userName: this.membersName,
        status: this.value
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage1 = val;
      this.payCostList();
    },
    //当前页查询数据条数变更
    handleSizeChange1(val) {
      this.pageSize = val;
      let data = {
        companyName: this.enterpriseName,
        userName: this.membersName,
        status: this.value
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.payCostList();
    },
    ready() {
      Quill.register({
        'formats/lineHeight': lineHeightStyle
      }, true);
    },
    onEditorChange(e) {
      if (this.ruleFormAdd.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    addWX() {
      this.centerDialogVisible2 = true
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleFormAdd.coverImg = `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      this.ruleFormAdd.coverImg = "";
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    close() {
      this.ruleFormAdd.title = ''
      this.ruleFormAdd.coverImg = ''
      this.ruleFormAdd.details = ''
      this.fileList = []
    },
    close_type() {
      this.ruleForm_type.name = ''
    },
    close_type1() {
      this.ruleForm_type1.name = ''
    },
    chooseShop() {
      this.centerDialogVisible1 = true
      this.getGoodsInfoPC()
    },
    chooseyuyueShop(){
      this.centerDialogVisible6 = true
      this.getGoodsInfoYY()
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: 9999,
        pageNum: 1,
      };
      try {
        const result = await getGoodsInfoPC(data);
        const {total, list} = JSON.parse(result?.data?.pageInfo);
        for (let i in list) {
          list[i].price = (list[i].price / 100).toFixed(2)
        }
        this.shopList = list
      } catch (error) {
      }
      // const reslut
    },
    async getGoodsInfoYY() {
      let data = {
        pageSize: 9999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await selectProductsPage(data);
        const {total, list} = result.data.pageInfo
        this.shopyyList = list
      } catch (error) {
      }
      // const reslut
    },
    chooseShopQD() {
      if (this.upDate == 1) {
        if (this.chooseShopList[this.activeIndex]) {
        } else {
          this.linshiList = []
        }
        this.centerDialogVisible1 = false
        let shopList = this.multipleSelection
        for (let i in shopList) {
          this.linshiList.push(shopList[i])
        }
        const map = new Map()
        const a = this.linshiList.filter(key => !map.has(key.goodsId) && map.set(key.goodsId, 1)) // 这里对name属性进行去重
        let b = {}
        if (this.shopTypeList.length == 0) {
          this.chooseShopList[this.activeIndex] = a
        }else {
          if (this.shopTypeList.length > this.activeIndex && this.chooseShopList[this.activeIndex]) {
            this.chooseShopList[this.activeIndex] = a
          } else {
            this.chooseShopList.push(b[this.activeIndex] = a)
          }
        }
      }else {
        if (this.chooseShopList[this.activeIndex]) {
          this.linshiList = this.chooseShopList[this.activeIndex]
        }else {
          this.linshiList = []
        }
        console.log(this.linshiList)
        this.centerDialogVisible1 = false
        let shopList = this.multipleSelection
        for (let i in shopList) {
          this.linshiList.push(shopList[i])
        }
        const map = new Map()
        const a = this.linshiList.filter(key => !map.has(key.goodsId) && map.set(key.goodsId, 1)) // 这里对name属性进行去重
        let b = {}
        if (this.shopTypeList.length == 0) {
          this.chooseShopList[this.activeIndex] = a
        }else {
          if (this.shopTypeList.length > this.activeIndex && this.chooseShopList[this.activeIndex]) {
            this.chooseShopList[this.activeIndex] = a
          } else {
            this.chooseShopList.push(b[this.activeIndex] = a)
          }
        }
      }
    },
    chooseShopYY() {
      if (this.upDate == 1) {
        if (this.chooseShopYYList[this.activeIndex]) {
        } else {
          this.linshiYYList = []
        }
        this.centerDialogVisible6 = false
        let shopList = this.multipleSelection3
        for (let i in shopList) {
          this.linshiYYList.push(shopList[i])
        }
        const map = new Map()
        const a = this.linshiYYList.filter(key => !map.has(key.productsId) && map.set(key.productsId, 1)) // 这里对name属性进行去重
        let b = {}
        if (this.shopTypeList.length == 0) {
          this.chooseShopYYList[this.activeIndex] = a
        }else {
          if (this.shopTypeList.length > this.activeIndex && this.chooseShopYYList[this.activeIndex]) {
            this.chooseShopYYList[this.activeIndex] = a
          } else {
            this.chooseShopYYList.push(b[this.activeIndex] = a)
          }
        }
        console.log(this.chooseShopYYList)
      }else {
        if (this.chooseShopYYList[this.activeIndex]) {
          this.linshiYYList = this.chooseShopYYList[this.activeIndex]
        }else {
          this.linshiYYList = []
        }
        this.centerDialogVisible6 = false
        let shopList = this.multipleSelection3
        for (let i in shopList) {
          this.linshiYYList.push(shopList[i])
        }
        const map = new Map()
        const a = this.linshiYYList.filter(key => !map.has(key.productsId) && map.set(key.productsId, 1)) // 这里对name属性进行去重
        let b = {}
        if (this.shopTypeList.length == 0) {
          this.chooseShopYYList[this.activeIndex] = a
        }else {
          if (this.shopTypeList.length > this.activeIndex && this.chooseShopYYList[this.activeIndex]) {
            this.chooseShopYYList[this.activeIndex] = a
          } else {
            this.chooseShopYYList.push(b[this.activeIndex] = a)
          }
        }
      }
    },
    deleteType(index) {
      this.shopTypeList.splice(index, 1);
      this.chooseShopList.splice(index, 1);
    },
    delShop(index) {
        this.$confirm('目前该文章已在线上页面展示，删除后将不再展示，确认删除该文章？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (let i in this.chooseShopList) {
            if (i == this.activeIndex) {
              // this.$set(this.chooseShopList, index, value)
              this.chooseShopList[i].splice(index, 1);
              this.linshiList.splice(index, 1)
              this.$forceUpdate();
            }
          }
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    delShopYY(index) {
        this.$confirm('目前该文章已在线上页面展示，删除后将不再展示，确认删除该文章？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (let i in this.chooseShopYYList) {
            if (i == this.activeIndex) {
              this.chooseShopYYList[i].splice(index, 1);
              this.linshiYYList.splice(index, 1)
              this.$forceUpdate();
            }
          }
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    chooseWZ() {
      this.centerDialogVisible3 = true
      this.getArt()
    },
    deleteType1(index) {
      this.typeList_WZ.splice(index, 1);
      this.chooseArticeList.splice(index, 1);
    },
    determine_upload() {
      this.$refs.ruleFormADD.validate(async (valid) => {
        if (valid) {
          try {
            if (this.upDateART == 1) {
              this.$message({
                message: "正在保存",
              });
              if (this.upDate == 1) {
                if (this.chooseArticeList[this.activeIndex1]) {
                } else {
                  this.linshiList_wz = []
                }
                let list = [{name:this.ruleFormAdd.title,coverUrl:this.ruleFormAdd.coverImg,content:this.ruleFormAdd.details}]
                let wzList = list
                for (let i in wzList) {
                  this.linshiList_wz.push(wzList[i])
                }
                const map = new Map()
                const a = this.linshiList_wz.filter(key => !map.has(key.workId) && map.set(key.workId, 1)) // 这里对name属性进行去重
                let b = {}
                if (this.typeList_WZ.length == 0) {
                  this.chooseArticeList[this.activeIndex1] = a
                }else {
                  if (this.typeList_WZ.length > this.activeIndex1 && this.chooseArticeList[this.activeIndex1]) {
                    console.log(a)
                    this.chooseArticeList[this.activeIndex1] = a
                  } else {
                    this.chooseArticeList.push(b[this.activeIndex1] = a)
                  }
                }
              }else {
                if (this.chooseArticeList[this.activeIndex1]) {
                  this.linshiList_wz = this.chooseArticeList[this.activeIndex1]
                }else {
                  this.linshiList_wz = []
                }

                let list = [{name:this.ruleFormAdd.title,coverUrl:this.ruleFormAdd.coverImg,content:this.ruleFormAdd.details,dataId:Date.now()}]
                let wzList = list
                for (let i in wzList) {
                  this.linshiList_wz.push(wzList[i])
                }
                console.log(this.linshiList_wz)
                const map = new Map()
                const a = this.linshiList_wz.filter(key => !map.has(key.dataId) && map.set(key.dataId, 1)) // 这里对name属性进行去重
                let b = {}
                if (this.typeList_WZ.length == 0) {
                  this.chooseArticeList[this.activeIndex1] = a
                }else {
                  if (this.typeList_WZ.length > this.activeIndex1 && this.chooseArticeList[this.activeIndex1]) {
                    console.log(a)
                    this.chooseArticeList[this.activeIndex1] = a
                  } else {
                    this.chooseArticeList.push(b[this.activeIndex1] = a)
                  }
                }
              }
              this.centerDialogVisible2 = false;
              this.$message({
                message: "保存成功",
                type: "success",
              });
            }else {
              this.determine_upload_update()
            }

          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getArt() {
      let data = {
        pageSize: 9999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        work_type: 0
      };
      try {
        this.loading = true;
        const result = await selectWorkList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        this.tableData_wz = list;
        this.total = total;
      } catch (error) {
        this.tableData_wz = []
        this.loading = false;
      }
    },
    chooseArtice() {
      if (this.upDate == 1) {
        if (this.chooseArticeList[this.activeIndex1]) {
        } else {
          this.linshiList_wz = []
        }
        console.log(this.linshiList_wz)
        this.centerDialogVisible3 = false
        let wzList = this.multipleSelection1
        for (let i in wzList) {
          this.linshiList_wz.push(wzList[i])
        }
        const map = new Map()
        const a = this.linshiList_wz.filter(key => !map.has(key.workId) && map.set(key.workId, 1)) // 这里对name属性进行去重
        let b = {}
        if (this.typeList_WZ.length == 0) {
          this.chooseArticeList[this.activeIndex1] = a
        }else {
          if (this.typeList_WZ.length > this.activeIndex1 && this.chooseArticeList[this.activeIndex1]) {
            this.chooseArticeList[this.activeIndex1] = a
          } else {
            this.chooseArticeList.push(b[this.activeIndex1] = a)
          }
        }
      }else {
        if (this.chooseArticeList[this.activeIndex1]) {
          this.linshiList_wz = this.chooseArticeList[this.activeIndex1]
        }else {
          this.linshiList_wz = []
        }
        this.centerDialogVisible3 = false
        let wzList = this.multipleSelection1
        for (let i in wzList) {
          this.linshiList_wz.push(wzList[i])
        }
        const map = new Map()
        const a = this.linshiList_wz.filter(key => !map.has(key.workId) && map.set(key.workId, 1)) // 这里对name属性进行去重
        let b = {}
        if (this.typeList_WZ.length == 0) {
          this.chooseArticeList[this.activeIndex1] = a
        }else {
          if (this.typeList_WZ.length > this.activeIndex1 && this.chooseArticeList[this.activeIndex1]) {
            this.chooseArticeList[this.activeIndex1] = a
          } else {
            this.chooseArticeList.push(b[this.activeIndex1] = a)
          }
        }
      }
    },
    async delWZ(index){
      this.$confirm('目前该文章已在线上页面展示，删除后将不再展示，确认删除该文章？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.upDate==1) {
          for (let i in this.chooseArticeList) {
            if (i == this.activeIndex1) {
              console.log(this.chooseArticeList[i])
              this.chooseArticeList[i].splice(index, 1);
              this.$forceUpdate();
            }
          }
        }else {
          for (let i in this.chooseArticeList) {
            if (i == this.activeIndex1) {
              if (this.chooseArticeList[i][index].createTime) {
                this.dataId = this.chooseArticeList[i][index].dataId
                let ajax = {
                  dataId:this.dataId
                }
                delPageWork(ajax);
                this.$message.success('删除成功')
                this.getDetails(this.id)
                this.chooseArticeList= []
              }else {
                this.chooseArticeList[i].splice(index,1)
                this.$forceUpdate()
              }
            }
          }
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    updateWZ(index){
      this.upDateART = 2
      this.articleTitle = '修改文章'
      this.index = index
      this.centerDialogVisible2 = true
      for (let i in this.chooseArticeList) {
        if (i == this.activeIndex1) {
          // 165788102   //自定义增加文章未上传dataId
          if (this.chooseArticeList[i][index].dataId < 165788102) {
            this.ruleFormAdd.title = this.chooseArticeList[i][index].title
            this.dataId = this.chooseArticeList[i][index].dataId
            this.ruleFormAdd.coverImg = this.chooseArticeList[i][index].img
            this.ruleFormAdd.details = this.chooseArticeList[i][index].content
            this.fileList = [{ url: this.chooseArticeList[i][index].img, uid: 1 }];
          } else {
            this.ruleFormAdd.title = this.chooseArticeList[i][index].name
            this.ruleFormAdd.coverImg = this.chooseArticeList[i][index].coverUrl
            this.ruleFormAdd.details = this.chooseArticeList[i][index].content
            this.fileList = [{ url: this.chooseArticeList[i][index].coverUrl, uid: 1 }];
          }
        }
      }
    },
    async determine_upload_update() {
        for (let i in this.chooseArticeList) {
          if (i == this.activeIndex1) {
            // 165788102   //自定义增加文章未上传dataId
            if (this.chooseArticeList[i][this.index].dataId < 165788102) {
              let ajax = {
                title:this.ruleFormAdd.title,
                dataId:this.dataId,
                img:this.ruleFormAdd.coverImg,
                content:this.ruleFormAdd.details
              }
              await updatePageWork(ajax);
              this.$message.success('修改成功')
              this.getDetails(this.id)
              this.centerDialogVisible2 = false
              this.chooseArticeList= []
            }else{
              this.chooseArticeList[i][this.index].name = this.ruleFormAdd.title
              this.chooseArticeList[i][this.index].coverUrl = this.ruleFormAdd.coverImg
              this.chooseArticeList[i][this.index].content = this.ruleFormAdd.details
              // this.fileList = [{ url: this.chooseArticeList[i][this.index].coverUrl, uid: 1 }];
              this.centerDialogVisible2 = false
            }
          }
        }
    },
    switchNav(){
      if (this.form.type == 1) {
        this.typeList_WZ = []
        this.chooseArticeList = []
        this.linshiList_wz = []
      }else {
        this.shopTypeList = []
        this.chooseShopList = []
        this.linshiList = []
        this.chooseShopYYList = []
      }
    }
  }
};
</script>
<style lang="scss" scope>
.shop_wrap {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 20px;
}

.type_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6EBEB;
}

.type_item {
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 0 10px;
}

.classAA {
  background: #51CD91;
  border-radius: 4px;
  color: #FFFFFF;
}

.classBB {
  border: 1px solid #51CD9D;
  border-radius: 4px;
  color: #51CD9D;
}

.choose_list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding: 20px 2px;
}

.choose_item {
  display: flex;
  justify-content: space-between;
  width: 23%;
  box-shadow: 0px 0px 30px 0px rgba(99, 99, 99, 0.1000);
  padding: 16px;
  margin: 0 8px 26px 8px;
}
.choose_item1 {
  display: flex;
  justify-content: space-between;
  width: 30%;
  box-shadow: 0px 0px 30px 0px rgba(99, 99, 99, 0.1000);
  padding: 16px;
  margin: 0 8px 26px 8px;
}


.choose_left1 {
  display: flex;
  width: 100%;
}

.choose_left1 > img {
  width: 80px;
  height: 50px;
  align-self: center;
  margin-right: 20px;
}

.choose_left1 > div {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.choose_left1 span:nth-child(2) {
  font-weight: bold;
  font-size: 17px;
}


.choose_left {
  display: flex;
  width: 100%;
}

.choose_left > img {
  width: 80px;
  height: 50px;
  align-self: center;
  margin-right: 20px;
}

.choose_left > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.choose_left span:nth-child(2) {
  font-weight: bold;
  font-size: 17px;
}

.del {
  display: flex;
  //flex-direction: column;
  justify-content: space-between;
}

.del > img {
  width: 28px;
  height: 28px;
  //background: rgba(81, 203, 205, .1);
  cursor: pointer;
  margin-bottom: 10px;
}

.update {
  height: 34px;
  line-height: 34px;
  align-self: center;
  padding: 0 20px;
  color: #333333;
  background: #FFFFFF;
  //border-color: #fbc4c4;
  border: 1px solid #333333;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 20px;
}
/deep/ .el-table__body-wrapper{
  height: 450px !important;
  overflow: auto !important;
}
</style>

